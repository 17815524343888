<template>
  <div class="container my-12 mx-auto px-4 md:px-12">
    <div class="flex flex-wrap -mx-1 lg:-mx-4">

      <!-- Column -->
      <div class="my-6 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3" v-for="item in items" :key="item.title">

        <!-- Article -->
        <article class="relative overflow-hidden rounded-lg shadow-lg transform hover:scale-105 transition ease-out duration-200">
          <component :is="item.href ? 'a':'span'" :href="item.href || ''">
            <img alt="{{ item.title }}" class="block h-60 w-full object-cover object-scale-down" :src="item.image">
          </component>
          <span class="bg-green-400 rounded-full absolute top-0 right-0 px-3 py-2 mx-1 my-1 text-white">{{ item.price }}</span>

          <header class="flex items-center justify-between leading-tight p-2 md:p-4">
            <h1 class="text-lg h-16">
              <component :is="item.href ? 'a':'span'" :class="item.href ? 'hover:underline' : ''" class="no-underline text-gray-800" :href="item.href || ''">
                {{ item.title }}
              </component>
            </h1>
          </header>

        </article>
        <!-- END Article -->

      </div>
      <!-- END Column -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  props: {
    items: Array,
  }
}
</script>
