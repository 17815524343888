<template>
  <h1 class="text-5xl text-left pl-20 pt-10">Runes ønsker</h1>
  <Cards :items="items" />
</template>

<script>
import Cards from './components/Cards.vue'

export default {
  name: 'App',
  components: {
    Cards,
  },
  data: function() {
    return {
      'items': [
        {
          'title': 'Ryggplate - Alpint',
          'href':  'https://www.xxl.no/scott-back-protector-airflex-22-23-ryggbeskytter-unisex-svart/p/1165619_1_Style?gtmProductListName=frequently-bought-together',
          'image': 'https://www.xxl.no/filespin/1c5b17f7e5e046d899336cf2767bc831?quality=90&resize=1920%2C1920',
          'price': '1200-1600,-'
        },
        {
          'title': 'Ryggplate - Alpint - Alternativ',
          'href':  'https://www.xxl.no/sweet-protection-back-protector-vest-24-25-ryggbeskyttelsesvest-ski-snowboard-herre-svart/p/1149946_1_Style',
          'image': 'https://www.xxl.no/filespin/1be49960fd39468cad7739195a7ecfce?quality=75&bgcolor=efefef&resize=1080%2C500',
          'price': '1200-'
        },
        {
          'title': 'Termokopp',
          'href':  'https://www.clasohlson.com/no/Thermos-JMW-Series-termokopp,-0,5-l-/p/31-1860-1',
          'image': 'https://images.clasohlson.com/medias/sys_master/he8/h6b/68463511207966.jpg',
          'price': '199,-'
        },
        //{
        //  'title': 'Treningsgenser, noe slikt?',
        //  'href':  'https://www.xxl.no/puma-teamliga-1-4-zip-top-treningsgenser-herre-bla/p/1188650_1_style?page=1',
        //  'image': 'https://www.xxl.no/filespin/31d29d51f2bd4609a8759f04bf3a5874?resize=722,722&quality=90&bgcolor=efefef',
        //  'price': '399,-'
        //},
        {
          'title': 'Dørgym',
          'href':  'https://www.xxl.no/titan-life-multi-grip-door-gym-dorgym-svart/p/1215416_1_Style',
          'image': 'https://www.xxl.no/filespin/bfa554ed8e3c4208ab0da45d08ec5096?quality=75&resize=640%2C640',
          'price': '500,-'
        },
        //{
        //  'title': 'Novablast 4 - 44.5',
        //  'href':  'https://www.xxl.no/asics-novablast-4-lopesko-herre-svart/p/1229136_1_Style?preselectedSize=44.5',
        //  'image': 'https://www.xxl.no/filespin/a7801fc2636b4dcbb30e5ec80d688e9b?quality=75&resize=640%2C640',
        //  'price': '1599,-'
        //},
        {
          'title': 'Vintersko 45',
          'href':  'https://www.eurosko.com/no/brands/okaki/okaki-2370-22247047',
          'image': 'https://www.eurosko.com/assets/blobs/22247047_1-e8d7238388.jpeg?preset=medium&dpr=2',
          'price': '1500,-'
        },
        //{
        //  'title': 'Hverdagssko 44.5',
        //  'href':  'https://www.xxl.no/asics-patriot-13-lopesko-herre-bla/p/1229773_1_Style',
        //  'image': 'https://www.xxl.no/filespin/e763f95a2a234f059feba795758d648d?quality=75&resize=640%2C640',
        //  'price': '-'
        //},
        {
          'title': 'Lesebrett - Enkelt / Avansert / Brukt / Kindle / Finn.no / etc ?',
          'href':  'https://www.elkjop.no/product/mobil-nettbrett-og-smartklokker/lesebrett/pocketbook-verse-ebook-lesebrett-8gb-mist-grey/644004',
          'image': 'https://www.elkjop.no/image/dv_web_D1800010021564651/644004/pocketbook-verse-ebook-lesebrett-8gb-mist-grey--pdp_zoom-3000--pdp_main-960.jpg',
          'price': 'Fra 500,- (brukt Finn.no)'
        },
        {
          'title': 'Klær: Sokker 44, boxere, T-skjorter (hvite, både runde og vhals)',
          'href': '',
          'image': 'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2F19%2Fa9%2F19a9ba8a2ac726e3e3f5222de380bdb5e7a8dac6.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BDESCRIPTIVESTILLLIFE%5D%2Cres%5Bm%5D%2Chmver%5B2%5D&call=url[file:/product/fullscreen]',
          'price': '99.90 - 500,-'
        },
        {
          'title': 'Lego - Fender Stratocaster',
          'href':  'https://lekekassen.no/lego-ideas-21329-fender-stratocaster',
          'image': 'https://d189539ycils2q.cloudfront.net/media/webp_image/catalog/product/cache/4ffcb2823444acbc328f989dd62e5069/l/e/lego-ideas-21329-fender-stratocaster-guitar-set.webp',
          'price': '1400,-'
        },
        {
          'title': 'Fender FE620 Gitar Gigbag',
          'href':  'https://www.gear4music.no/no/Gitar-and-Bass/Fender-FE620-Gitar-Gigbag/234N',
          'image': 'https://r2.gear4music.com/media/27/279405/600/preview.jpg',
          'price': '750,-'
        },
        //{
        //  'title': 'Akustisk gitar - Yamaha ',
        //  'href':  '',
        //  'image': '',
        //  'price': '4500 - 5500,-'
        //},
        //{
        //  'title': 'Sort paraply - Feks fra Swims',
        //  'href': '',
        //  'image': 'https://villoid-shop.imgix.net/s/files/1/1513/8164/products/PFjnollQSrWCrvEggo2t_43301_085_640x.jpg?v=1599686403&trim=color&trimtol=10&trimcolor=fff&fit=&w=&h=&auto=format',
        //  'price': '400 - 600,-'
        //},
        //{
        //  'title': 'Dreiejern',
        //  'href':  'https://www.tredreieutstyr.no/nettbutikk/dreiejern/startsett-dreiejern/komplett-startsett-6-jern-hamlet',
        //  'image': 'https://www.tredreieutstyr.no/image/startsett-dreiejern/150hct6-dsc00995.jpg?width=500&pad=true',
        //  'price': '2295,-'
        //},
      ],
    }
  }
}
</script>

<style>
</style>
